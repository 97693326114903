<template>
  <v-container>
    Admin Dashboard
  </v-container>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    await this.$store.dispatch('GetAllProjects');
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
